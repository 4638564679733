.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.datepickerdiv{
  width: 242px;

}

.selectImage{
  border-radius: 5px;
  object-fit: contain;
}

.selectCard{
  cursor: pointer;
}

.messageBox{
  white-space: pre-wrap;
}

.uk-card-default {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}

* {
  box-sizing: border-box;
}
:root {
  --color-white: #fff;
  --color-black: #333;
  --color-gray: #75787b;
  --color-gray-light: #bbb;
  --color-gray-disabled: #e8e8e8;
  --color-green: #f0506e;
  --color-green-dark: #383;
  --font-size-small: .75rem;
  --font-size-default: .875rem;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
}
.progress-bar li {
  flex: 2;
  position: relative;
  padding: 0 0 0 0;
  font-size: var(--font-size-default);
  line-height: 1.5;
  color: var(--color-green);
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  border-bottom: 2px solid var(--color-gray-disabled);
}
.progress-bar li:first-child,
.progress-bar li:last-child {
  flex: 1;
}
.progress-bar li:last-child {
  text-align: right;
}
.progress-bar li:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--color-gray-disabled);
  border-radius: 50%;
  border: 2px solid var(--color-white);
  position: absolute;
  left: calc(50% - 7px);
  bottom: -7px;
  z-index: 3;
  transition: all .2s ease-in-out;
}
.progress-bar li:first-child:before {
  left: 0;
}
.progress-bar li:last-child:before {
  right: 0;
  left: auto;
}
.progress-bar span {
  transition: opacity .3s ease-in-out;
}
.progress-bar li:not(.is-active) span {
  opacity: 0;
}
.progress-bar .is-complete:not(:first-child):after,
.progress-bar .is-active:not(:first-child):after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: -50%;
  z-index: 2;
  border-bottom: 2px solid var(--color-green);
}
.progress-bar li:last-child span {
  width: 200%;
  display: inline-block;
  position: absolute;
  left: -100%;
}
 
.progress-bar .is-complete:last-child:after,
.progress-bar .is-active:last-child:after {
  width: 200%;
  left: -100%;
}
 
.progress-bar .is-complete:before {
  background-color: var(--color-green);
}
 
.progress-bar .is-active:before
{
  background-color: var(--color-white);
  border-color: var(--color-green);
}

.fixed {
  position: fixed;
  /* position : absolute; */
  top: 0px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0px;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 0px;
  right: 0px;

  padding-left: 12.5px;
  padding-right: 12.5px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-top-left-radius: 10px;
  background-color: #f0506e;
  color: #fff;
}

.fb, .fb:hover, .fb:focus {
  color: #fff;
}

.textarea_fixed {
  resize: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen {
  #userid{
    display: none;
  }
}

@media print {
  #navBarAdmin{
    display: none;
  }

  #userid{
    display: block;
  }
}

.background-image-display {
  background-image: url("/background.png");
  background-size: cover;
  background-position: center center;
}

.navbar {
  z-index: 1;
}

.loading {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  background: #FFFFFF;
  z-index: 2147483647;
}

.loading img {
  margin-top: 100px;
}

.label {
    margin-left: 215px;
    display: block;
    padding-top: 7px;
    font-size: 1rem;
    min-height: 32px;
}

.status-available {
  color: #26AA78;
  background-color: #edfbf6;
  font-size: 14px;
}

.status-away {
  color: #faa05a;
  background-color: #fff6ee;
  font-size: 14px;
}

.uk-comment-primary {
  background-color: #EEEEEE;
  border-radius: 5px;
}

.conselorName {
  font-weight: bold;
  margin-top: -5px;
  margin-bottom: -5px;
}

.counselorSmallText {
  font-size: 12px;
}

.label-muted {
  background-color: #666;
}

/*===================
iPhoneのメッセージ風
=====================*/
/*フレームとフォント*/
.kaiwa.imessage {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  background: white;
  line-height: 1.4;
  overflow: hidden;
  border: 0 !important;
}
/*フキダシ共通*/
.kaiwa.imessage .fukidasi {
  position: relative;
  display: inline-block;
  max-width: 500px;
  margin: 1px 10px 6px;
  padding: 9px 14px;
  border-radius: 10px;
  overflow-wrap: break-word;
  clear: both;
  box-sizing: content-box;/*はてな用*/
}
/*しっぽをつけないとき*/
.kaiwa.imessage .fukidasi.notail {
  margin-bottom: 0;
}
/*フキダシ左*/
.kaiwa.imessage .fukidasi.left {
  float: left;
  background: #e9e9ed;
}
/*フキダシ右*/
.kaiwa.imessage .fukidasi.right {
  float: right;
  background: #2793fa;
  color: white;
}
/*しっぽ共通*/
.kaiwa.imessage .fukidasi::after {
  position: absolute;
  content: "";
  width: 17px;
  height: 17px;
  bottom: 0;
}
/*しっぽ左*/
.kaiwa.imessage .fukidasi.left::after {
  left: -6px;
  border-radius: 0px 0  17px 0px/ 0px 0 13px 0; 
  box-shadow: -14px 2px 0 -3px #e9e9ed inset; 
}
/*しっぽ右*/
.kaiwa.imessage .fukidasi.right::after {
  right: -6px;
  border-radius: 0px 0  0 17px/ 0px 0 0 13px; 
  box-shadow: 14px 2px 0 -3px #2793fa inset; 
}
/*しっぽをつけないとき*/
.kaiwa.imessage .fukidasi.notail::after {
  content: none;
}

.kaiwa.imessage .delete-button {
  text-align: right;
}

.delete-link { 
  color: white;
}